import { BaseModel } from '..';
import { Attachment, IntermittentFault } from '../intermittentFaults';
import { AdditionalCrew } from '../trips';

const initialState: FlightsState = {
  filters: {},
  flightsMap: new Map(),
  aircraftFlightsMaps: new Map(),
  lastFetched: 0,
  pagination: {},
  params: {},
  ttl: 30000,
  dirty: false,
  data: {
    params: [],
    filters: [],
  },
};

export enum FlightsActionTypes {
  DELETE_FLIGHT = 'flights/delete',
  SAVE = 'flights/save',
  SAVE_ALL = 'flights/saveAll',
  SAVE_FLIGHTS_ONLY = 'flights/saveFlightsOnly',
  SAVE_FLIGHT_DETAILS = 'flights/saveFlightDetails',
  SET_PARAMS = 'flights/setParams',
  SET_DIRTY = 'flights/setDirty',
  SET_FILTERS = 'flights/setFilters',
}

export interface DeleteFlightActionTypes {
  type: FlightsActionTypes.DELETE_FLIGHT;
  payload: {
    id: string;
    aircraftId?: string;
  };
}

export interface SaveFlightsActionTypes {
  type: FlightsActionTypes.SAVE;
  payload: Flight;
}

export interface SaveAllFlightsActionTypes {
  type: FlightsActionTypes.SAVE_ALL;
  payload: { list: Flight[]; pagination: {} };
}

export interface SaveFlightsOnlyFlightsActionTypes {
  type: FlightsActionTypes.SAVE_FLIGHTS_ONLY;
  payload: { flights: Flight[]; aircraftId: string };
}

export interface SaveFlightDetailsFlightsActionTypes {
  type: FlightsActionTypes.SAVE_FLIGHT_DETAILS;
  payload: Flight;
}

export interface SetParamsFlightsActionTypes {
  type: FlightsActionTypes.SET_PARAMS;
  payload: Flight;
}

export interface SetDirtyFlightsActionTypes {
  type: FlightsActionTypes.SET_DIRTY;
  payload: boolean;
}

export interface SetFiltersFlightsActionTypes {
  type: FlightsActionTypes.SET_FILTERS;
  payload: Flight;
}

type FlightsAction =
  | DeleteFlightActionTypes
  | SaveFlightsActionTypes
  | SaveAllFlightsActionTypes
  | SaveFlightsOnlyFlightsActionTypes
  | SaveFlightDetailsFlightsActionTypes
  | SetParamsFlightsActionTypes
  | SetDirtyFlightsActionTypes
  | SetFiltersFlightsActionTypes;

export default function (state = initialState, action: FlightsAction): FlightsState {
  switch (action.type) {
    case FlightsActionTypes.SAVE: {
      const flightsMap = new Map(state.flightsMap.entries());
      flightsMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        flightsMap,
        dirty: false,
      };
    }
    case FlightsActionTypes.SAVE_ALL: {
      const flightsMap = new Map(); // @TODO: build map from previous entries and use client side filtering
      if (action.payload && 'list' in action.payload && Array.isArray(action.payload.list)) {
        action.payload.list.forEach((flight) => {
          flightsMap.set(flight.id, {
            ...flightsMap.get(flight.id),
            ...flight,
          });
        });
      }
      return {
        ...state,
        flightsMap,
        pagination: action?.payload?.pagination || {},
        lastFetched: Date.now(),
        dirty: false,
      };
    }
    case FlightsActionTypes.SAVE_FLIGHTS_ONLY: {
      const aircraftFlightsMaps = new Map(state.aircraftFlightsMaps.entries());
      // Check if we already have flights set for this aircraft
      const flightsMap = new Map();
      action.payload.flights.forEach((flight) => {
        flightsMap.set(flight.id, flight);
      });
      aircraftFlightsMaps.set(action.payload.aircraftId, {
        lastFetched: Date.now(),
        flightsMap,
      });
      return {
        ...state,
        aircraftFlightsMaps,
        dirty: false,
      };
    }
    case FlightsActionTypes.SAVE_FLIGHT_DETAILS: {
      const flightsMap = new Map(state.flightsMap.entries());
      flightsMap.set(action.payload.id, {
        ...flightsMap.get(action.payload.id),
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        flightsMap,
        dirty: false,
      };
    }
    case FlightsActionTypes.SET_PARAMS: {
      return {
        ...state,
        params: action.payload,
      };
    }
    case FlightsActionTypes.SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case FlightsActionTypes.SET_DIRTY: {
      return {
        ...state,
        dirty: action.payload,
      };
    }
    case FlightsActionTypes.DELETE_FLIGHT: {
      const flightsMap = new Map(state.flightsMap.entries());
      const aircraftFlightsMaps = new Map(state.aircraftFlightsMaps.entries());
      if (action.payload && action.payload.id) {
        flightsMap.delete(action.payload.id);
      }
      if (action.payload && action.payload?.aircraftId) {
        const newFlightsMap = aircraftFlightsMaps.get(action.payload.aircraftId)?.flightsMap;
        if (newFlightsMap) {
          newFlightsMap.delete(action.payload.id);
          aircraftFlightsMaps.set(action.payload.aircraftId, {
            flightsMap: newFlightsMap,
            lastFetched: Date.now(),
          });
        }
      }
      return {
        ...state,
        flightsMap,
        aircraftFlightsMaps,
        dirty: true,
      };
    }
    default:
      return state;
  }
}

export interface FlightsState {
  filters: {};
  flightsMap: Map<string, Flight>;
  aircraftFlightsMaps: Map<
    string,
    {
      lastFetched: number;
      flightsMap: Map<string, BaseFlight | Flight>;
    }
  >;
  lastFetched: number;
  pagination: {};
  params: {};
  ttl: number;
  dirty: boolean;
  data: {
    params: [];
    filters: [];
  };
}

export interface BaseFlight extends BaseModel {
  id: string;
  aircraft: {
    registration: string;
    id: string;
    type: string;
  };
  aircraft_registration: string;
  arrival_airport: unknown;
  actual_arrival_airport: unknown;
  departure_airport: unknown;
  landings_count: number;
  marked_as_checked: boolean;
  sector_number: number;
  source: unknown;
  status: string;
  time_landing: string;
  time_offblocks: string;
  time_onblocks: string;
  time_takeoff: string;
  trip: {
    id: string;
    number: number;
    date: string;
    srp_number: number;
  };
}

export interface Flight extends BaseFlight {
  apu_seconds: number;
  apu_reading: number;
  apu_cycles: number;
  apu_cycles_reading: number;
  apu_mode: string;
  arrival_airport_id: string;
  actual_arrival_airport_id: string;
  arrival_fuel: number;
  departure_airport_id: string;
  departure_fuel_adjustment: number;
  departure_fuel_pre_uplifts: number;
  hobbs_arrival: number;
  hobbs_departure: number;
  last_updated: number;
  remote_deice: unknown;
  baggage_weight: number;
  gpu_seconds: number;
  gpu_start: number;
  gpu_end: number;
  passengers_children: number;
  passengers_female: number;
  passengers_infants: number;
  passengers_male: number;
  pressurised_cycle: boolean;
  max_flight_level: number;
  cycles: {
    airframe: number;
    engine_1?: number;
    engine_2?: number;
    engine_3?: number;
    engine_4?: number;
  };
  pilot_flying: {
    id: string;
    first_name: string;
    last_name: string;
  };
  pilot_monitoring: {
    id: string;
    first_name: string;
    last_name: string;
  };
  pic: {
    id: string;
    first_name: string;
    last_name: string;
  };
  sic: {
    id: string;
    first_name: string;
    last_name: string;
  };
  pilot_performing_takeoff: {
    id: string;
    first_name: string;
    last_name: string;
  };
  pilot_performing_landing: {
    id: string;
    first_name: string;
    last_name: string;
  };
  trip: Trip;
  deice_events: [];
  deice_events_attributes: [];
  fuel_uplifts: FuelUplift[];
  fuel_uplifts_attributes: FuelUplift[];
  departure_fuel_log: {
    aircraft_planned_departure: number;
    aircraft_actual_uplift: number;
    aircraft_value_post_event: number;
    aircraft_unit: string;
  };
  oil_uplift: {
    unit: unknown;
    engine_1: unknown;
    engine_2: unknown;
  };
  fluid_uplifts: FluidUplift[];
  fluid_uplifts_attributes: FluidUplift[];
  departure_airport_details: {
    latitude: unknown;
    longitude: unknown;
    timezone: unknown;
    proximate_to_coast: unknown;
    weather_category: unknown;
    metar: unknown;
  };
  number: number;
  prior_corrosion_risk_score: unknown;
  prior_corrosion_risk_details: string;
  parked_duration: unknown;
  wet_weather_percentage: number;
  arrival_airport_details: {
    latitude: unknown;
    longitude: unknown;
    timezone: unknown;
    weather_category: unknown;
    metar: unknown;
  };
  postflight_signature_url: string;
  postflight_signature_image_url: string;
  postflight_signature_data: string;
  preflight_signature_url: string;
  preflight_signature_image_url: string;
  preflight_signature_data: string;
  preflight_custom_data?: any;
  postflight_custom_data?: any;
  attachments: [];
  sector_attachments: Attachment[];
  other_attachments: [];
  defects: [];
  versions: [];
  additional_crews?: AdditionalCrew[];
  additional_crews_attributes?: AdditionalCrew[];
  abandonment_reason?: string;
  intermittent_faults?: IntermittentFault[];
  actual_fuel_burn?: number;
  planned_fuel_burn?: number;
  fuel_burn_justification?: string;
  preflight_weight?: number;
  airborne_seconds?: number;
  flight_risk_assessment_result?: FRATResult;
  pilot_performing_landing_id: string;
  pilot_performing_takeoff_id: string;
}

export interface FRATResult {
  flight_id: string;
  id: string;
  max_possible_weight: number;
  percentage: number;
  risk_level: FRATRiskLevel;
  weight: number;
}

export enum FRATRiskLevel {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface FluidUplift {
  id: string;
  fluid_destination: string;
  fluid_destination_number: number;
  fluid_type: string;
  uplift: string;
  uplift_unit: string;
  carried_forwards: string;
  initial_value: string;
  hydraulic_circuit_id: string;
}

export interface FuelUplift extends BaseModel {
  id: string;
  aircraft_value_post_event: string;
  density: string;
  aircraft_unit: string;
  is_deleted: false;
  truck_planned_uplift: string;
  truck_actual_uplift: string;
  aircraft_planned_departure: string;
  aircraft_actual_uplift: string;
  truck_unit: string;
  attachments: [];
  _destroy?: boolean;
}

export interface Trip extends BaseModel {
  id: string;
  aircraft_id: string;
  operator_id: string;
  date: string;
  number: number;
  srp_number: number;
  srp_pages: number;
  srp_reference: string;
  flight_seconds: number;
  apu_seconds: number;
  landings: number;
  cycles: number;
  fuel_delta: number;
  trip_category: unknown;
  aircraft_registration: string;
  aircraft: {
    registration: string;
    id: string;
    type: string;
    oil_unit: string;
    fuel_unit: string;
  };
  attachments: [];
  start_airport: unknown;
  end_airport: unknown;
  callsign: string;
  status: string;
  flights: [
    {
      id: string;
      time_offblocks: string;
      time_onblocks: string;
      time_takeoff: string;
      time_landing: string;
      status: string;
      departure_airport: unknown;
      departure_airport_id: unknown;
      arrival_airport: unknown;
      arrival_airport_id: unknown;
      fuel_uplift: string;
      landings: number;
      flight_seconds: number;
      fuel_used: string;
      captain: string;
      first_officer: string;
      oil_uplift: {
        unit: string;
        engine_1: number;
        engine_2: number;
      };
    },
  ];
  captain: {
    id: string;
    last_updated: number;
    first_name: string;
    last_name: string;
    position: string;
    email: string;
    type: string;
    role: string;
    avatar_url: unknown;
    organisation: {
      id: string;
      name: string;
      address: string;
      aoc_number: string;
      date_format: string;
      ddl_template: unknown;
      logo_url: unknown;
      dark_logo_url: unknown;
      custom_signoff_text: {};
    };
  };
  first_officer: {
    id: string;
    last_updated: number;
    first_name: string;
    last_name: string;
    position: string;
    email: string;
    type: string;
    role: string;
    avatar_url: unknown;
    organisation: {
      id: string;
      name: string;
      address: string;
      aoc_number: string;
      date_format: string;
      ddl_template: unknown;
      logo_url: unknown;
      dark_logo_url: unknown;
      custom_signoff_text: {};
    };
  };
}
