import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import ResolutionDetails from '../../WorkPackDrawer/Components/ResolutionDetails';
import { DashboardState } from '../../../models';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';
import ViewDefectDrawerDetails from './ViewDefectDrawerDetails';
import EditDefectDrawerDetails from './EditDefectDrawerDetails';

interface DefectDetailsProps {
  defect: Defect;
  updateDefectData: (changes: any[]) => void;
  editDefect: boolean;
  signature: string;
  formChanged: boolean;
  requiredSettings: {
    troubleShootingStepsRename: string;
    deferralApprovalReferenceRename: string;
    defectRename: string;
    reportedByTypeEnabled: boolean;
    stageOfFlightEnabled: boolean;
    stageOfFlightText: string;
    eropsRename: string;
    part145ApprovalNumberRename: string;
    engineerRename: string;
    engineerLicenceNumberRename: string;
    melNameOverride: string;
    cdlNameOverride: string;
    nefNameOverride: string;
    casNameOverride: string;
    otherNameOverride: string;
    showRichTextMel: boolean;
  };
}

const BorderLine = styled.div`
  width: 680px;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-left: -22px;
  @media (max-width: 450px) {
    width: calc(100vw - 40px);
  }
`;

const StyledImg = styled.img`
  height: 70px;
  max-width: 240px;
  @media (max-width: 451px) {
    max-width: 180px;
  }
`;

const DefectDetails: React.FC<DefectDetailsProps> = ({
  defect,
  editDefect,
  updateDefectData,
  signature,
  requiredSettings,
  formChanged,
}) => {
  const { formatMessage } = useIntl();
  const { userSettings } = useSelector((state: DashboardState) => state);
  const {
    userSettings: { dateFormat },
    drawer: { mode, editResolved },
  } = useSelector((state: DashboardState) => state);

  const lastMXEvent = defect?.mx_events && defect?.mx_events[defect?.mx_events.length - 1];

  const isMobile = window.innerWidth < 451;

  const extendedOn = (): string => {
    if (!defect || !defect.rectification_interval_extension) {
      return '-';
    }
    return moment.utc(defect.rectification_interval_extension.extension_date).format(`${userSettings.dateFormat}`);
  };

  const newDueDate = (): string => {
    if (
      !defect ||
      !defect.rectification_interval_extension ||
      !defect.rectification_interval_extension.extension_date_due
    ) {
      return '-';
    }

    return moment.utc(defect.rectification_interval_extension.extension_date_due).format(`${userSettings.dateFormat}`);
  };

  const release = { ...lastMXEvent };
  return (
    <Card marginBottom={20} identifier="DefectDetailsWrapper">
      {mode === 'view' ? (
        <ViewDefectDrawerDetails
          defectData={defect}
          editResolved={editResolved}
          updateDefectData={updateDefectData}
          requiredSettings={requiredSettings}
        />
      ) : (
        <EditDefectDrawerDetails defectData={defect} updateDefectData={updateDefectData} formChanged={formChanged} />
      )}
      {!editDefect && (
        <>
          {!editDefect &&
          defect?.deferred &&
          defect?.display_data?.category &&
          defect?.display_data?.category !== 'A' ? (
            <>
              <BorderLine />
              <FlexWrapper column marginBottom={30} identifier="DefectDetails-RectificationIntervalExtension">
                <Label marginBottom={20} marginTop={30} fontWeight={600}>
                  {formatMessage({ id: 'form.labels.rectificationIntervalExtension' })}
                </Label>
                <GridWrapper columns="1fr 1fr 1fr">
                  <FlexWrapper column identifier="DefectDetails-RectificationIntervalExtension-ExtensionAdded">
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extensionAdded' })}
                    </Label>
                    <span>{defect?.rectification_interval_extension ? 'YES' : 'NO'}</span>
                  </FlexWrapper>
                  <FlexWrapper column identifier="DefectDetails-RectificationIntervalExtension-ExtendedOn">
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extendedOn' })}
                    </Label>
                    <span>{extendedOn()}</span>
                  </FlexWrapper>
                  <FlexWrapper column identifier="DefectDetails-RectificationIntervalExtension-ExtensionDue">
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extensionDue' })}
                    </Label>
                    <span>{newDueDate()}</span>
                  </FlexWrapper>
                </GridWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.deferred_by?.id ? (
            <>
              <BorderLine />
              <FlexWrapper
                justifyContent="space-between"
                alignItems={isMobile ? '' : 'center'}
                marginTop={20}
                marginBottom={20}
                column={isMobile}
                height={isMobile ? null : 40}
              >
                <FlexWrapper alignItems="center">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.deferredBy' })}
                  </Label>
                  <Label fontWeight={600} marginRight={15}>
                    <span>
                      {defect?.deferred_by?.first_name} {defect?.deferred_by?.last_name}
                    </span>
                  </Label>
                  {(signature || defect?.deferral_signature_data || defect?.approval_signature_data) && (
                    <StyledImg alt="Signature" src={`data:image/png;base64, ${defect?.deferral_signature_data}`} />
                  )}
                </FlexWrapper>
                <FlexWrapper flexShrink="0">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.deferredOn' })}
                  </Label>
                  <Label fontWeight={600}>
                    {(defect?.deferred_at && moment(defect?.deferred_at).utc().format(`${dateFormat} HH:mm`)) || '-'}
                  </Label>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.edited_by?.first_name ? (
            <>
              <BorderLine />
              <FlexWrapper
                justifyContent="space-between"
                alignItems={isMobile ? '' : 'center'}
                marginTop={20}
                marginBottom={20}
                column={isMobile}
                height={isMobile ? null : 40}
                identifier="DefectDetails-EditedBy"
              >
                <FlexWrapper alignItems="center">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.editedBy' })}
                  </Label>
                  <Label fontWeight={600} marginRight={15}>
                    {defect?.edited_by?.first_name ? defect?.edited_by?.first_name : ''}{' '}
                    {defect?.edited_by?.last_name || null}
                  </Label>
                  {(signature || defect?.deferral_signature_data || defect?.approval_signature_data) && (
                    <StyledImg alt="Signature" src={`data:image/png;base64, ${defect?.approval_signature_data}`} />
                  )}
                </FlexWrapper>
                <FlexWrapper flexShrink="0">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.editedOn' })}
                  </Label>
                  <Label fontWeight={600}>
                    {(defect?.edited_date && moment(defect?.edited_date).utc().format(`${dateFormat} HH:mm`)) || '-'}
                  </Label>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.mx_events && defect?.mx_events.length > 0 ? (
            <>
              <BorderLine />
              <ResolutionDetails release={{ release }} poNum={lastMXEvent.workpack_reference} noCard />
            </>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default DefectDetails;
