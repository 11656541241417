import React, { useState } from 'react';
import { Button, Search } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import TFLoading from '../../TFLoading';
import { Defect } from '../../../models/defects';
import { MELItem } from '../../../models/mels';
import { WorkpackForPublicPage } from '../../../models/workpacks';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import Card from './Card';
import DisplayBookItems from './DisplayBookItems';
import DeferUntil from './DeferUntil';
import ViewNewLayoutMelDetails from './ViewNewLayoutMelDetails';
import ViewMelDetails from './ViewMelDetails';
import { handleDeferralTimeChange, useDisplayDefectTypeLabel } from './utils';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface BookItemsSectionProps {
  bookItems: MELItem[];
  defectData: Defect;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  updateDefectData: (changes: { key: string; value: any }[]) => void;
  isBookItemsLoading: boolean;
  filteredItems: any[];
  handleLocalBookItemChange: (itemId: string, rectId: string) => void;
  formChanged: boolean;
  overrideDetails: any;
  aircraftId: string;
  workpack?: WorkpackForPublicPage;
}

const DefectTypeHeader = styled.div`
  font-weight: 500;
  margin: 20px 0;
  color: ${({ theme }): string => theme.colours.black};
`;

const SearchWrapper = styled.div`
  margin-bottom: 20px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 4px;
  width: 100%;
  @media (max-width: 450px) {
    margin: 20px 0;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
`;

const isMobile = window.innerWidth < 451;

const BookItemsSection: React.FC<BookItemsSectionProps> = ({
  bookItems,
  defectData,
  setSearchInput,
  updateDefectData,
  isBookItemsLoading,
  searchInput,
  filteredItems,
  handleLocalBookItemChange,
  formChanged,
  overrideDetails,
  aircraftId,
  workpack,
}) => {
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const { formatMessage } = useIntl();
  return (
    <div data-testid="BookItemsSection">
      <DefectTypeHeader data-testid="BookItemsSection--DefectTypeHeader">
        {useDisplayDefectTypeLabel(defectData, overrideDetails)}
      </DefectTypeHeader>
      <div>
        <TFLoading loading={isBookItemsLoading} contain />
        {bookItems?.length > 0 ? (
          <>
            <SearchWrapper>
              <Search
                onClear={(): void => setSearchInput('')}
                onChange={(e): void => setSearchInput(e.currentTarget.value.toLowerCase())}
              />
            </SearchWrapper>
            <Card maxHeight="500px" id="BookItemWrapper" identifier="BookItemsWrapper">
              <DisplayBookItems
                bookItems={filteredItems}
                updateDefectData={updateDefectData}
                handleBookItemChange={handleLocalBookItemChange}
                defect={defectData}
                searchInput={searchInput}
              />
            </Card>
            <DateTimeWrapper>
              <DateTimePicker
                dateTime={defectData?.deferred_at}
                headerDate="Deferral date"
                headerTime="Deferral time"
                handleDateTimeChange={(value) => handleDeferralTimeChange(value, defectData, updateDefectData)}
                disabled={!unlockDeferralTime}
              />
              <DeferUntil defect={defectData} formChanged={formChanged} />
              <ButtonWrapper>
                {!unlockDeferralTime && (
                  <Button primary={false} height="24px" onClick={(): void => setUnlockDeferralTime(true)}>
                    <StyledIcon src={padlockIcon} alt="padlock icon" />
                    {formatMessage({ id: 'text.unlock' })}
                  </Button>
                )}
              </ButtonWrapper>
            </DateTimeWrapper>
            {defectData?.book_item?.id &&
              !isMobile &&
              (overrideDetails?.showRichTextMel ? (
                <ViewNewLayoutMelDetails defect={defectData} isBookTableVisible />
              ) : (
                <ViewMelDetails defect={defectData} isBookTableVisible edit />
              ))}
          </>
        ) : (
          <ManuallyEnterMELDetails
            defect={defectData}
            updateDefectData={updateDefectData}
            formChanged={formChanged}
            aircraftId={aircraftId}
            workpack={workpack}
          />
        )}
      </div>
    </div>
  );
};

BookItemsSection.defaultProps = {
  workpack: undefined,
};

export default BookItemsSection;
